<template>
 <section class="section">
    <div class="container">
      <div class=" columns is-multiline is-full-width is-centered">
        <div class="column is-12">
          <div class="steps">
            <!-- is-active -->
            <!-- is-success -->
            <!-- is-completed -->
            <div class="step-item" :class="step === 1 ? 'is-active' : 'is-completed'">
              <div class="step-marker">1</div>
              <div class="step-details">
                <p class="step-title">Acerca</p>
              </div>
            </div>
<!--             <div class="step-item" :class="{'is-active': step === 2, 'is-completed': step > 2}">
              <div class="step-marker">2</div>
              <div class="step-details">
                <p class="step-title">Categorias</p>
              </div>
            </div> -->
            <div class="step-item" :class="{'is-active': step === 2, 'is-completed': step > 2}">
              <div class="step-marker">2</div>
              <div class="step-details">
                <p class="step-title">Ubicacion</p>
              </div>
            </div>
            <div class="step-item" :class="{'is-active': step === 3}">
              <div class="step-marker">3</div>
              <div class="step-details">
                <p class="step-title">Fotos</p>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-12">
          <div v-if="step === 1" >
            <general />
          </div>
<!--           <div
            class="column is-12"
            v-if="step === 2"
          >
            <category />
          </div> -->
          <div
            class="column is-12"
            v-if="step === 2"
          >
            <address-c />
          </div>
          <div
            class="column is-12"
            v-if="step === 3"
          >
            <photos />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapState, mapMutations } from 'vuex'

export default {
  middleware: 'validate-code',
  name: 'register',
  mounted () {
    this.clearStateNewProduct()
  },
  components: {
    general: () => import('@/components/NewProduct/General.vue'),
    /* category: () => import('@/components/NewProduct/Category.vue'), */
    addressC: () => import('@/components/NewProduct/Address.vue'),
    photos: () => import('@/components/NewProduct/Photos.vue')
  },
  computed: {
    ...mapState({
      step: state => state.newProduct.step
    })
  },
  methods: {
    ...mapMutations(['clearStateNewProduct'])
  }
}
</script>

<style scoped>
  .is-full-width{
    width: 100%;
  }
  .padding-center{
    padding: 0px 15%;
  }
  .padding-top{
    padding-top: 50px;
  }
</style>
